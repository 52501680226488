<template>
  <div class="form-right-box-crm" id="formRight">
    <formRight :type="type"></formRight>
  </div>
</template>
<script>
import formRight from "@/components/lists/formRight";
import { mapMutations } from "vuex";
export default {
  name: "index",
  components: {
    formRight,
  },
  data() {
    return {
      type: 1,
    };
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.name == "crmLists") {
          this.type = Number(this.$route.query.menu);
          this.setFormState(this.$route.query.menu);
        }
      },
      deep: true,
    },
  },
  created() {
    this.type = Number(this.$route.query.menu);
    this.setFormState(this.$route.query.menu);
  },
  methods: {
    ...mapMutations("jiyang/form", ["setFormState"]),
  },
};
</script>
<style lang="scss">
.form-right-box-crm {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  position: relative;
}
</style>
